// Pure
@import "constants";
@import "mixins";

:root {
  font-size: 12px;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.02em;
}

body,
html {
  margin: 0;
  padding: 0;
  color: #fff;
  -webkit-text-size-adjust: 100%;
  overscroll-behavior: none;
  @include background-gradient;
}

h1.exception {
  margin-top: 150px;
  color: white;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 1px;
}

a {
  cursor: pointer;
}

.assaia-ui-portal {
  position: fixed;
  inset: 0;
  pointer-events: none;
}

* {
  outline: none;
}
